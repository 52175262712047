import React from 'react';

export function Header() {
  return (
    <header>
      <a href="/">
        <img src="/images/logo.png" width="32" height="32" />
        Little Martian
      </a>
      <span style={{ flexGrow: 1 }} />
      <a href="http://discord.gg/fH7agQaHPx" className="Header-discord">
        Discord
      </a>
      <a href="https://twitter.com/MartiansGame" className="Header-twitter">
        Twitter
      </a>
      <a href="/devlog">
        Devlog
      </a>
      <a href="/demo">
        Demo!
      </a>
    </header>
  )
}